.custom-modal {
    /* width: 90%; */
    max-width: 90%;
    max-height: 90vh;
    height: 100%
}


.zip-progress {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
}

/* Cell text truncation */
.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust this value to your desired maximum width */
    display: inline-block;
}







